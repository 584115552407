/*TODO: remove all styles */
/*@import "theme.css";*/
/*@import "general.css";*/
/*@import "components/index.css";*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 1279px) {
    .card {
        /*border:1px solid red;*/
        @apply card-compact;
    }
    .prose :where(h2):not(:where([class~="not-prose"],[class~="not-prose"] *)) {
        font-size: 1em;
    }
    .pagination-container .pagination-item {
        padding:0 2px;
        min-width: 0px;
    }
}